import { createTheme, Typography } from "@mui/material";
import { AppProvider, DashboardLayout } from "@toolpad/core";
import React, { useState } from "react";
import logo from "./../../images/logo.png";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosConfig";
import { useParams } from "react-router-dom";
import DialogBox from "../User/DialogBox";

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function Verification() {
  const { email } = useParams();

  const [showalert, setshowalert] = useState(false);
  const [alertText, setAlertText] = useState("");

  const SendEmail = async () => {
    try {
      const result = await axiosInstance({
        method: "get",
        url: `/user/link/${email}`,
        responseType: "json",
      });

      if (result.data.success === true) {
        setAlertText(result.data.message);
        setshowalert(true);
      } else {
        setAlertText(result.data.message);
        setshowalert(true);
      }
    } catch (error) {
      setAlertText(error?.response?.data);
      setshowalert(true);
    }
  };

  return (
    <AppProvider
      theme={demoTheme}
      branding={{
        logo: <img src={logo} alt="Nitro logo" />,
        title: "",
      }}
    >
      <DialogBox
        openState={showalert}
        title={"Message"}
        data={alertText}
        setOpenState={setshowalert}
      />
      <DashboardLayout hideNavigation>
        <Typography
          variant="h3"
          alignItems="center"
          style={{ textAlign: "center", marginTop: "50px" }}
          gutterBottom
        >
          Verify Email
        </Typography>

        <span style={{ textAlign: "center" }}>
          If you have not received verification email please check your spam.
          <button
            onClick={() => {
              SendEmail();
            }}
          >
            Click to send
          </button>
        </span>
        <span style={{ textAlign: "center" }}>
          Go to <Link to={"/login"}>Login</Link>
        </span>
      </DashboardLayout>
    </AppProvider>
  );
}
