import * as React from "react";
import { createTheme } from "@mui/material/styles";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import Grid from "@mui/material/Grid2";
import LoadingButton from "@mui/lab/LoadingButton";

import logo from "./../../images/logo.png";
import {
  AutocompleteElement,
  FormContainer,
  PasswordElement,
  PasswordRepeatElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { Box, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosConfig";
import DialogBox from "../User/DialogBox";
import { useNavigate } from "react-router-dom";
const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function Signup(props) {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState("");
  const [repassword, setRepassword] = React.useState("");
  const [showalert, setshowalert] = React.useState(false);
  const [alertText, setAlertText] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [ListLoading, setListLoading] = React.useState(false);

  React.useEffect(
    () => async () => {
      setListLoading(true);
      const teams = await axiosInstance({
        method: "get",
        url: "user/teams",
        responseType: "json",
      });
      setListLoading(false);
      const list = teams.data.data.map((v, key) => {
        return { id: v.id, label: v.name };
      });
      setOptions(list);
    },
    []
  );

  const signup = async (formData) => {
    setLoading(true);
    const data = {
      name: name,
      email: email,
      password: password,
      repassword: repassword,
      team_id: formData.team.id,
    };
    try {
      const result = await axiosInstance({
        method: "post",
        url: "user",
        responseType: "json",
        data: data,
      });

      if (result.data.success === true) {
        navigate(`/verification/${result.data.data.email}`);
      } else {
        setAlertText(result.data.message);
        setshowalert(true);
      }
    } catch (error) {
      setAlertText(error.response.data.message);
      setshowalert(true);
    }
    setLoading(false);
  };

  return (
    <AppProvider
      theme={demoTheme}
      branding={{
        logo: <img src={logo} alt="Nitro logo" />,
        title: "",
      }}
    >
      <DialogBox
        openState={showalert}
        title={"Message"}
        data={alertText}
        setOpenState={setshowalert}
      />
      <DashboardLayout hideNavigation>
        <Grid
          container
          rowSpacing={1}
          style={{ padding: 50 }}
          columnSpacing={{ xs: 1, xl: 2, lg: 2 }}
          sx={{ justifyContent: "center" }}
        >
          <Grid size={{ xs: 12, md: 12, lg: 3, xl: 3 }}>
            <Typography
              variant="h3"
              alignItems="center"
              style={{ textAlign: "center", marginTop: "50px" }}
              gutterBottom
            >
              Signup
            </Typography>
            <Box
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: "10px",
                padding: "20px",
              }}
              component={Paper}
            >
              <FormContainer onSuccess={(data) => signup(data)}>
                <TextFieldElement
                  name="Name"
                  fullWidth
                  required
                  type={"text"}
                  label={"Full Name"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  margin="dense"
                />
                <TextFieldElement
                  name="Email"
                  fullWidth
                  required
                  type={"email"}
                  label={"Email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="dense"
                />

                <AutocompleteElement
                  label="Teams"
                  name="team"
                  required
                  options={options}
                  loading={ListLoading}
                />

                <PasswordElement
                  margin={"dense"}
                  label={"Password"}
                  fullWidth
                  required
                  name={"password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <PasswordRepeatElement
                  passwordFieldName={"password"}
                  name={"password-repeat"}
                  margin={"dense"}
                  label={"Repeat Password"}
                  onChange={(e) => setRepassword(e.target.value)}
                  required
                  fullWidth
                />

                <LoadingButton
                  variant="outlined"
                  style={{ marginTop: "15px" }}
                  margin="dense"
                  loading={loading === true ? true : false}
                  type="submit"
                  sx={{ display: "block", marginBottom: "10px" }}
                >
                  Signup
                </LoadingButton>
                <span>
                  Already have an account? <Link to={"/login"}>Login</Link>
                </span>
              </FormContainer>
            </Box>
          </Grid>
        </Grid>
      </DashboardLayout>
    </AppProvider>
  );
}

export default Signup;
